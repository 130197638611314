import React from 'react'
import styled from '@emotion/styled'
import { Heading } from 'rebass'
import Flex from './Flex'

const SectionHeading = ({
  as = 'h2', children, action = null, ...props
}) => (
  <Flex
    {...props}
    as="header"
    flexWrap="wrap"
    alignItems="center"
    flexDirection="row"
    justifyContent="space-between"
  >
    <Heading
      as={as}
      py={1}
      fontSize={3}
      variant="caps"
    >
      {children}
    </Heading>
    {action}
  </Flex>
)

export default styled(SectionHeading)`
  border-top: 3px solid ${(props) => props.theme.colors.primary[50]};
`
