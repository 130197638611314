import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LayoutSection } from '../layout'
import { EventCalendar } from '../events'

const WidgetEventCalendar = () => {
  const { wp: data } = useStaticQuery(graphql`
    query WidgetEventCalendarQuery {
      wp {
        eventCalendar {
          count
          date
        }

        themeOptions {
          eventsPage {
            uri
          }
        }
      }
    }
  `)

  const eventCountByDate = data
    .eventCalendar
    .reduce((hash, item) => ({
      ...hash, [item.date]: item.count,
    }), {})

  return (
    <LayoutSection heading="Programnaptár">
      <EventCalendar
        eventCountByDate={eventCountByDate}
        eventsUri={data.themeOptions.eventsPage.uri}
      />
      <noscript>
        A programnaptár javascript nélkül nem megy.
      </noscript>
    </LayoutSection>
  )
}

export default WidgetEventCalendar
