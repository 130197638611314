import React from 'react'
import styled from '@emotion/styled'
import format from 'date-fns/format'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import addDays from 'date-fns/addDays'
import startOfWeek from 'date-fns/startOfWeek'
import hu from 'date-fns/locale/hu'
import {
  Flex, Box, Text, Heading, Button, Link,
} from '../ui'
import EventCalendarDay from './EventCalendarDay'
import prevIcon from '../../data/images/icons/icon-prev-dark.svg'
import nextIcon from '../../data/images/icons/icon-next-dark.svg'
import useCalendar from './useCalendar'

function range(n) {
  return [...Array(n).keys()]
}

const rows = range(6)
const cols = range(7)

function createCalendarMatrix({ year, month, weekStartsOn } = {
  year: getYear(new Date()),
  month: getMonth(new Date()),
  weekStartsOn: 1,
}) {
  const matrix = []
  const date = new Date(year, month)
  let curDate = startOfWeek(date, { weekStartsOn })

  rows.forEach(() => {
    const week = []
    cols.forEach(() => {
      week.push(curDate)
      curDate = addDays(curDate, 1)
    })

    matrix.push(week)
  })

  return matrix
}

const dayLabels = ['h', 'k', 'sz', 'cs', 'p', 'sz', 'v']

const StyledButton = styled(Button)`
  font-size: 0;
`

const EventCalendar = ({ eventCountByDate, eventsUri }) => {
  const {
    minMonth,
    maxMonth,
    selectedYear: year,
    selectedMonth: month,
    selectPrevMonth,
    selectNextMonth,
  } = useCalendar()

  const matrix = createCalendarMatrix({ year, month, weekStartsOn: 1 })

  return (
    <Flex
      flexDirection="column"
    >
      <Flex
        pl={3}
        as="header"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading
          fontSize={3}
          fontWeight="normal"
          display="flex"
          flexDirection="row"
        >
          {year}
          <Text
            as="span"
            fontWeight="bold"
            ml={1}
          >
            {format(new Date(year, month, 1), 'MMMM', { locale: hu })}
          </Text>
        </Heading>
        <Box>
          <StyledButton
            variant="image"
            onClick={selectPrevMonth}
            aria-label="Előző hónap"
            disabled={month === minMonth}
          >
            <img
              width={10}
              height={17}
              alt="Előző hónap"
              src={prevIcon}
            />
          </StyledButton>

          <StyledButton
            variant="image"
            aria-label="Következő hónap"
            onClick={selectNextMonth}
            disabled={month === maxMonth}
          >
            <img
              width={10}
              height={17}
              alt="Következő hónap"
              src={nextIcon}
            />
          </StyledButton>
        </Box>
      </Flex>

      <Flex
        pt={1}
        pb={2}
        textAlign="center"
        flexDirection="row"
      >
        {dayLabels.map((dayLabel, i) => (
          <Text
            flex={1}
            fontWeight="bold"
            key={`${dayLabel}-${String(i)}`}
          >
            {dayLabel}
          </Text>
        ))}
      </Flex>
      {
        matrix.map((row, i) => (
          <Flex
            my={0.5}
            key={String(i)}
            flexDirection="row"
          >
            {row.map((date) => {
              const formattedDate = format(date, 'yyyy-MM-dd')
              const eventCount = eventCountByDate[formattedDate] || 0
              const isOutsideMonth = getMonth(date) !== month

              const item = eventCount
                ? (
                  <Link to={`${eventsUri}?fromDate=${formattedDate}`}>
                    <EventCalendarDay
                      date={date}
                      eventCount={eventCount}
                      isOutsideMonth={isOutsideMonth}
                    />
                  </Link>
                ) : (
                  <EventCalendarDay
                    date={date}
                    isOutsideMonth={isOutsideMonth}
                  />
                )

              return (
                <Flex
                  flex={1}
                  key={date.toString()}
                  textAlign="center"
                  justifyContent="center"
                >
                  {item}
                </Flex>
              )
            })}
          </Flex>
        ))
      }
    </Flex>
  )
}

export default EventCalendar
